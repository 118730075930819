<template>
  <div id="policy">
    <div id="title">
      <nav class="btn-back" @click.stop="back">
        <a href="#"><span class="sp-none">もどる</span></a>
      </nav>
      <img id="logo" src="@/assets/img/top-login/logo-alltop.png" />
    </div>

    <div id="content">
      <h1>{{ data.title }}</h1>
      <p>
        {{ data.text }}
      </p>

      <div v-for="(d, i) in data.body" :key="i">
        <h2>{{ d.title }}</h2>

        <p v-if="!!d.text">{{ d.text }}</p>
        <!-- ul -->
        <ul v-if="!!d.ul">
          <li v-for="l in d.ul" :key="l">{{ l }}</li>
        </ul>
        <!-- ol -->
        <ol v-if="!!d.ol">
          <li v-for="l in d.ol" :key="l">{{ l }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
const data = {
  title: 'プライバシー・ポリシー',
  text:
    '株式会社ひめぎんソフト（以下「当社」）は、' +
    '以下のとおり個人情報保護方針を定め、' +
    '個人情報保護の仕組みを構築し、' +
    '全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、' +
    '個人情報の保護を推進致します。',
  body: [
    {
      title: '個人情報の管理',
      text:
        '当社は、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、' +
        'セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、' +
        '安全対策を実施し個人情報の厳重な管理を行ないます。'
    },
    {
      title: '個人情報の利用目的',
      text:
        '当社では、Webサイトのアクセス解析及び、' +
        '利用状況などの取得を行っており、' +
        '今後のコンテンツの充実や教育目的での分析に利用します。'
    },
    {
      title: '個人情報の第三者への開示・提供の禁止',
      text:
        '当社は、お客さまよりお預かりした個人情報を適切に管理し、' +
        '次のいずれかに該当する場合を除き、' +
        '個人情報を第三者に開示いたしません。',
      ol: [
        'お客さまの同意がある場合',
        'お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合',
        '法令に基づき開示することが必要である場合'
      ]
    },
    {
      title: 'ご本人の照会',
      text:
        'お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、' +
        'ご本人であることを確認の上、対応させていただきます。'
    },
    {
      title: '法令、規範の遵守と見直し',
      text:
        '当社は、保有する個人情報に関して適用される日本の法令、' +
        'その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。'
    },
    { title: 'お問い合せ', text: '株式会社ひめぎんソフト' }
  ]
};

export default {
  data() {
    return {
      data
    };
  },
  methods: {
    back() {
      this.$router.push('/');
    }
  }
};
</script>

<style>
body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ',
    Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  background-image: linear-gradient(
    -45deg,
    #fdf5cf 25%,
    #fff0b3 25%,
    #fff0b3 50%,
    #fdf5cf 50%,
    #fdf5cf 75%,
    #fff0b3 75%,
    #fff0b3
  );
  background-size: 18px 18px;
  background-repeat: repeat;
}
#title {
  line-height: 1.5;
  max-width: 800px;
  padding: 20px;
  margin: 20px auto 0;
}
#title img {
  object-fit: contain;
  max-width: 100%;
}
#content {
  background-color: white;
  line-height: 1.5;
  max-width: 800px;
  height: auto;
  padding: 20px;
  margin: 10px auto 20px;
  border-radius: 25px;
}

h1 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 34px;
  letter-spacing: 0.25px;
}
h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 24px;
  letter-spacing: 0px;
}

ol {
  all: initial;
  margin-left: 36px;
  display: list-item;
  list-style-type: disc;
  text-align: left;
}

.btn-back {
  position: absolute;
  z-index: 30;
}
.btn-back a {
  display: table;
  height: 50px;
  border: 5px solid #fb861f;
  background-color: #fb861f;
  border-radius: 50px;
  background-position: center left;
  background-repeat: no-repeat;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
  background-image: url('./ability/img/common/icon-btn-back.svg');
}
.btn-back a span {
  font-size: 2rem;
  display: table-cell;
  vertical-align: middle;
  padding: 0px 15px 0px 45px;
}

@media screen and (max-aspect-ratio: 2/3) {
  .btn-back a:hover {
    border: 5px solid #ffb257;
    background-color: #ffb257;
  }
  .btn-back a {
    width: 50px;
  }
  .sp-none {
    display: none !important;
  }
}
</style>
